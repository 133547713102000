import React, {useEffect} from "react";
import moment from "moment";
import {useCamera} from "../../state/CamerasSlice";
import {HashLoader} from "react-spinners";

const NoVideoImg = require("../_assets/no-video.png");

type CameraViewProps = {
    cameraName: string,
    time?: number
};

export const CameraView = (props: CameraViewProps) => {
    const cameraState = useCamera(props.cameraName);
    const activeImage = cameraState.useActiveImage();

    useEffect(() => {
        cameraState.setTime(props.time);
        if(!props.time) {
            const timer = setInterval(cameraState.setTime, 10000);
            return () => clearInterval(timer);
        }
    }, [props.time, cameraState.camera?.name]);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "black",
                width: 400,
                aspectRatio: 310/232,
                cursor: "pointer",
                boxSizing: "border-box"
            }}
        >
            <div style={{height: 0, marginRight: "auto", overflow: "visible"}}>
                <div
                    style={{
                        position: "relative",
                        backgroundColor: "black",
                        fontSize: 12,
                        fontWeight: 500,
                        color: "white",
                        padding: "1px 3px",
                    }}
                >
                    {`${props.cameraName} ${activeImage ? "-" : ""} ${activeImage?.time ? moment(new Date(activeImage.time * 1000)).format("YYYY-MM-DD HH:mm:ss") : ""}`}
                </div>
            </div>
            {
                activeImage?.uri ?
                    <img style={{width: "100%"}} src={activeImage.uri} alt={""}/>
                    :
                    (
                        cameraState.camera.loading ?
                        <div style={{ display: "flex", flex: 1, alignItems: "center", justifyContent: "center"}}>
                            <HashLoader loading={true} size={100} color={"white"}  />
                        </div>
                        :
                        <img src={NoVideoImg} alt={""} style={{objectFit: "contain", width: "100%"}}/>
                    )
            }
        </div>
    );
};