import {API_URL} from "../environment";
import {Buffer} from 'buffer';

const apiPathBuilder = (path: string, params?: object) => {
    let out = `${API_URL}/${path}`;
    let out_params = "";
    if(params)
        Object.entries(params).forEach(([key, value]) => {
            out_params += `${key}=${value.toString?.() || value}&`
        });
    if(out_params[out_params.length - 1] === "&")
        out_params = out_params.slice(0, out_params.length - 1);
    if(out_params?.length)
        out = `${out}?${out_params}`;
    return out;
};

export const API = {
    getImage: async (cameraName: string, time?: number) => {
        try {
            const response = await fetch(apiPathBuilder("camera/image", { cameraName, time }));

            if(response.ok) {
                try {
                    const sTime = response.headers.get("time");
                    const iTime = sTime ? Number.parseInt(sTime) : 0;
                    const buffer = Buffer.from(await response.arrayBuffer());

                    return { buffer, time: iTime };
                }
                catch (e) {}
            }
            console.error(await response.text());
        }
        catch (e) {
            console.error(e);
        }
    },
    getImageTimes: async (cameraName: string, fromTime: number, toTime?: number) => {
        try {
            const response = await fetch(apiPathBuilder("camera/times", { cameraName, fromTime, toTime }));

            if(response.ok) {
                try {
                    return (await response.json()) as number[];
                }
                catch (e) {}
            }
            console.error(await response.text());
        }
        catch (e) {
            console.error(e);
        }
    },
    getCameraList: async () => {
        try {
            const response = await fetch(apiPathBuilder("camera/list"));

            if(response.ok) {
                try {
                    return (await response.json()) as { name: string, lat?: string, lon?: string, online?: boolean }[];
                }
                catch (e) {}
            }
            console.error(await response.text());
        }
        catch (e) {
            console.error(e);
        }
    }
};