import {asyncThunkCreator, buildCreateSlice, PayloadAction} from "@reduxjs/toolkit";
import {API} from "../services/api";
import {ImageProcessing} from "../services/imageProcessing";
import {useDispatch, useSelector} from "react-redux";
import {StoreDispatch, StoreState} from "./index";
import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {useEffect} from "react";

type ImageType = {
    time: number,
    uri: string,
};

type CameraModelType = {
    name: string,
    lat?: string,
    lon?: string,
    online?: boolean,
    selected?: boolean,
    loading?: boolean
};

type CameraCacheType = {
    cache?: ImageType[],
    activeImage?: ImageType,
};

type CamerasSliceType = {
    list: (CameraModelType&CameraCacheType)[]
};

const initialState: CamerasSliceType = {
    list: []
};

const cacheRange = 180;

export const CamerasSlice = buildCreateSlice({ creators: { asyncThunk: asyncThunkCreator } })({
    name: 'data',
    initialState,
    reducers: (create) => ({
        setCameraActiveImage: create.reducer((state, action: PayloadAction<{ cameraName: string, image: ImageType }>) => {
            const { cameraName, image } = action.payload;
            const cam = state.list.find(x => x.name === cameraName);
            if(!cam)
                return;

            cam.activeImage = image;
        }),
        insertImageIntoCache: create.reducer((state, action: PayloadAction<{ cameraName: string, image: ImageType }>) => {
            const { cameraName, image } = action.payload;
            const cam = state.list.find(x => x.name === cameraName);
            if(cam)
                cam.cache = [ ...(cam.cache?.filter(x => x.time !== image.time) || []), image ];
        }),
        purgeCache: create.reducer((state, action: PayloadAction<{ cameraName: string, before?: number, after?: number }>) => {
            const { cameraName, before, after } = action.payload;
            const cam = state.list.find(x => x.name === cameraName);
            if(cam)
                cam.cache = cam.cache
                    ?.filter(x =>
                        (!before || x.time > before) &&
                        (!after || x.time < after) &&
                        (before || after)
                    );
        }),
        populateCache: create.asyncThunk(
            async (params: { cameraName: string, time: number }, apiThunk) => {
                try {
                    const state = apiThunk.getState() as StoreState;
                    const cam = state.cameras.list.find(x => x.name === params.cameraName);
                    if(!cam)
                        return;

                    apiThunk.dispatch(CamerasSlice.actions.updateCamera({ name: cam.name, loading: true }));

                    const fromTime = params.time - cacheRange;
                    const toTime = params.time + cacheRange;

                    const times = await API.getImageTimes(cam.name, fromTime, toTime);
                    if(!times || !times.length)
                        return;

                    const sortedTimes = times.sort((a, b) => {
                        const aDiff = Math.abs(a-params.time);
                        const bDiff = Math.abs(b-params.time);

                        return aDiff - bDiff;
                    });

                    for(const listTime of sortedTimes) {
                        let img = cam.cache?.find(x => x.time === listTime);

                        if(!img) {
                            const imgRes = await API.getImage(cam.name, listTime);
                            if(imgRes) {
                                const uri = ImageProcessing.imgUri(imgRes.buffer, "jpeg");
                                img = { time: listTime, uri };
                                apiThunk.dispatch(CamerasSlice.actions.insertImageIntoCache({ cameraName: cam.name, image: img }));
                            }
                        }

                        if(img && img.time <= params.time && img.time >= params.time - 120 && (!cam.activeImage || img.time > cam.activeImage.time))
                            apiThunk.dispatch(CamerasSlice.actions.setCameraActiveImage({ cameraName: cam.name, image: img }));
                    }

                    apiThunk.dispatch(CamerasSlice.actions.updateCamera({ name: cam.name, loading: false }));

                    const removeBefore = fromTime - 120;
                    apiThunk.dispatch(CamerasSlice.actions.purgeCache({ cameraName: cam.name, before: removeBefore, after: toTime }));
                }
                catch (e) {
                    console.error(`populateCache -> ${e}`);
                }
            }
        ),
        setCameraTime: create.asyncThunk(
            async (params: { cameraName: string, time: number }, apiThunk) => {
                const state = apiThunk.getState() as StoreState;
                const cam = state.cameras.list.find(x => x.name === params.cameraName);
                if(!cam)
                    return;

                await apiThunk.dispatch(CamerasSlice.actions.populateCache({ cameraName: cam.name, time: params.time }));

                let times = [...cam.cache.map(x => x.time)];
                times = times.filter(x => x <= params.time).sort((a, b) => b - a);
                const last = times[0];

                if(last && last >= params.time-120)
                    apiThunk.dispatch(CamerasSlice.actions.setCameraActiveImage({ cameraName: cam.name, image: cam.cache.find(x => x.time === last) }));
            }
        ),
        updateCamera: create.reducer((state, action: PayloadAction<CameraModelType>) => {
            const cameraModel = action.payload;
            let camera = state.list.find(x => x.name === cameraModel.name);
            if(!camera) {
                camera = { ...cameraModel } as CameraModelType&CameraCacheType;
                state.list.push(camera);
            }
            else
                Object.assign(camera, cameraModel);
            if(!camera.selected) {
                delete camera.cache;
                delete camera.activeImage;
            }
        }),
        fetchCameraList: create.asyncThunk(
            async (arg, thunkAPI) => {
                const cameraList = await API.getCameraList();
                for(const cam of cameraList)
                    thunkAPI.dispatch(CamerasSlice.actions.updateCamera(cam));
            }
        )
    })
});

export const PersistedCamerasSliceReducer =
    persistReducer({ key: 'cameras', storage, blacklist: ["cache", "activeImage"] }, CamerasSlice.reducer);

export const useCamerasInit = () => {
    const dispatch = useDispatch<StoreDispatch>();

    useEffect(() => {
        dispatch(CamerasSlice.actions.fetchCameraList());
        const timer = setInterval(() => {
            dispatch(CamerasSlice.actions.fetchCameraList());
        }, 1000 * 60 * 5);
        return () => clearInterval(timer);
    }, []);
};

export const useCameraList = () => {
    const cameraList = useSelector((state: StoreState) => state.cameras.list);
    return [...(cameraList || [])].sort((a, b) => {
        if(!a.online && b.online)
            return 1;
        if(a.online && !b.online)
            return -1;
        if(a.name > b.name)
            return 1;
        if(a.name < b.name)
            return -1;
        return 0;
    });
};

export const useSelectedCameras = () => {
    const cameraList = useCameraList();
    const selectedCameras = cameraList.filter(x => x.selected);
    useSelector(() => selectedCameras.length);

    return selectedCameras;
};

export const useCamera = (cameraName: string) => {
    const dispatch = useDispatch<StoreDispatch>();
    const camera = useSelector((state: StoreState) => state.cameras.list.find(x => x.name === cameraName));

    return {
        camera,
        toggleSelected: () => {
            if(camera)
                dispatch(CamerasSlice.actions.updateCamera({ name: camera.name, selected: !camera.selected }));
        },
        setTime: (time?: number) => {
            if(camera) {
                if(time)
                    dispatch(CamerasSlice.actions.setCameraTime({ cameraName, time }));
                else
                    dispatch(CamerasSlice.actions.setCameraTime({ cameraName, time: Math.round(Date.now()/1000) }));
            }

        },
        useActiveImage: () => useSelector(() => camera?.activeImage),
        useOnline: () => useSelector(() => !!camera?.online),
        useSelected: () => useSelector(() => !!camera?.selected)
    };
};