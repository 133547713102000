import React from "react";
import {CameraView} from "./cameraView";
import {useSelectedCameras} from "../../state/CamerasSlice";

export const CamerasView = () => {
    const selectedCameras = useSelectedCameras();

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: 3
            }}
        >
            {selectedCameras.map(x => <CameraView key={x.name} cameraName={x.name}/>)}
        </div>
    );
};