import {configureStore} from '@reduxjs/toolkit'
import {persistStore} from 'redux-persist';
import {PersistedAppSliceReducer} from "./AppSlice";
import {PersistedCamerasSliceReducer} from "./CamerasSlice";

export const store = configureStore({
    reducer: {
        app: PersistedAppSliceReducer,
        cameras: PersistedCamerasSliceReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type StoreState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type StoreDispatch = typeof store.dispatch