// import Upscaler from "upscaler";
// import * as esrgan_slim from '@upscalerjs/esrgan-slim';
// import maxim_deblurring from "@upscalerjs/maxim-deblurring";
// import maxim_enhancement from "@upscalerjs/maxim-enhancement";
// import maxim_dehazing_outdoor from "@upscalerjs/maxim-dehazing-outdoor";
//
// const upscaler = new Upscaler({ model: esrgan_slim.x2 });
// const deblurring = new Upscaler({ model: maxim_deblurring });
// const enhancement = new Upscaler({ model: maxim_enhancement });
// const dehazing = new Upscaler({ model: maxim_dehazing_outdoor });

export const ImageProcessing = {
    imgUri: (imageBuffer: Buffer, type: string) => {
        return `data:image/${type};base64,${imageBuffer.toString("base64")}`;
    },
    enhance: async (imgUri: string) => {
        //imgUri = await upscaler.upscale(imgUri);
        // imgUri = await upscaler.upscale(imgUri);
        return imgUri;
    },
};