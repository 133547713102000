import {asyncThunkCreator, buildCreateSlice} from "@reduxjs/toolkit";
import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";

type AppSliceType = {

};

const initialState: AppSliceType = {

};

export const AppSlice = buildCreateSlice({ creators: { asyncThunk: asyncThunkCreator } })({
    name: 'app',
    initialState,
    reducers: (create) => ({

    })
});


export const useAppInit = () => {

};

export const PersistedAppSliceReducer = persistReducer({ key: 'app', storage }, AppSlice.reducer);