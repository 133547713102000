import WebFont from "webfontloader";
import {CameraList} from "./_components/cameraList";
import {useAppInit} from "../state/AppSlice";
import {CamerasView} from "./_components/camerasView";
import {useCamerasInit} from "../state/CamerasSlice";

WebFont.load({
    google: {
        families: ["Roboto:300,400,500"],

    }
});

export const App = () => {
    useAppInit();
    useCamerasInit();

    return (
        <div
            style={{
                position: "absolute",
                display: "flex",
                flexDirection: "row",
                top: 0,
                left: 0,
                backgroundColor: "black",
                width: "100vw",
                height: "100vh",
                overflow: "hidden",
                userSelect: "none",
                fontFamily: "Roboto"
            }}
        >
            <div
                style={{
                    height: "100vh",
                    overflowY: "auto",
                    width: "auto",
                    paddingBottom: "5vh",
                    boxSizing: "border-box",
                }}
            >
                <CameraList/>
            </div>
            <div
                style={{
                    flex: 1,
                    height: "100vh",
                    overflowY: "auto",
                    paddingBottom: "15vh",
                    boxSizing: "border-box",
                }}
            >
                <CamerasView/>
            </div>
        </div>
    );
};
