import React from "react";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import {useCamera, useCameraList} from "../../state/CamerasSlice";

const CameraRow = (props: { cameraName: string }) => {
    const cameraState = useCamera(props.cameraName);
    const selected = cameraState.useSelected();
    const online = cameraState.useOnline();

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                color: online ? "white" : "red",
                justifyContent: "space-between",
                padding: "5px 10px"
            }}
        >
            <span style={{ minWidth: 200}}>{props.cameraName}</span>
            <Toggle
                checked={selected}
                onChange={() => cameraState.toggleSelected()}
            />
        </div>
    );
};


export const CameraList = () => {
    const cameraList = useCameraList();
    const sortedList = [...(cameraList || [])]
        .sort((a, b) => {
            if(!a.online && b.online)
                return 1;
            if(a.online && !b.online)
                return -1;
            return 0;
        });

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
            }}
        >
            {
                sortedList.map((x, i) =>
                    <React.Fragment key={i}>
                        <CameraRow cameraName={x.name}/>
                    </React.Fragment>
                )
            }
        </div>
    );
};

